import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CryptoJS from 'crypto-js'
const Bookitem = (props) => {
  const navigate=useNavigate()
const deleteproduct=async(e)=>{

  let x=window.confirm("Are you sure")
 if(x)
 {
  const response=await fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/deleteproduct`,{
    method:"DELETE",
    headers:{
      "Content-Type":"application/json",
  
    },body:JSON.stringify({id:props.ele._id,imgtext1:props.ele.imgtext1,imgtext2:props.ele.imgtext2,imgtext3:props.ele.imgtext3,imgtext4:props.ele.imgtext4})
  })
const json=await response.json()

if(json.success)
{
  
window.location.reload(true)
}

 }

}

  return (
  <>
  
  

  

  <div className="scard">
    <div className="image">
      <img src={props.ele.bimg1} alt="Image1"  />
      <img src={props.ele.bimg2} alt="image2"  />
      <img src={props.ele.bimg3} alt="image3"  />
      <img src={props.ele.bimg4} alt="image4"  />
   
    </div>
    <div className="content">
      <h5>{props.ele.booktitle}</h5>
   <p>
   {props.ele.bookdescription.slice(0,400)}...
   </p>
   <div className='d-flex gap-1'>

   <button type="button" className="btn btn-success text-nowrap" disabled>STOCK <i className="fa-solid fa-arrow-right"></i> {props.ele.stock}</button>
   <button type="button" className="btn btn-light text-nowrap" disabled>No Of Pages <i className="fa-solid fa-arrow-right text-dark "></i> {props.ele.pages}</button>
   <button type="button" className="btn btn-warning text-nowrap" disabled>Binding <i className="fa-solid fa-arrow-right "></i> {props.ele.binding}</button>
   <button type="button" className="btn btn-danger text-nowrap" disabled>Publication <i className="fa-solid fa-arrow-right"></i> {props.ele.publication}</button>
   </div>
   <div className='d-flex gap-1 my-2'>

<button type="button" className="btn btn-danger text-nowrap" disabled>MRP <i className="fa-solid fa-arrow-right"></i> {props.ele.mrp}</button>
<button type="button" className="btn btn-warning text-nowrap" disabled>Discount Rate <i className="fa-solid fa-arrow-right"></i> {props.ele.discount}</button>
<button type="button" className="btn btn-light text-nowrap" disabled>Actual Price <i className="fa-solid fa-arrow-right text-dark "></i> {props.ele.actualprice}</button>

</div>
<div className='d-flex gap-1'>

<button type="button" className="btn btn-success text-nowrap" disabled>Category <i className="fa-solid fa-arrow-right"></i> {props.ele.category}</button>
<button type="button" className="btn btn-light text-nowrap" disabled>Auther <i className="fa-solid fa-arrow-right text-dark "></i> {props.ele.auther}</button>
<button type="button" className="btn btn-info text-nowrap" disabled>ISBN <i className="fa-solid fa-arrow-right text-dark "></i> {props.ele.isbn}</button>

</div>
<div className='d-flex flex-row-reverse gap-2'>

<button className='btn btn-dark' onClick={deleteproduct} ><i className="fa-solid fa-trash-can"></i> Delete Product</button>
<Link to={`/updateitem/${props.ele._id}`}><button className='btn btn-dark'  ><i className="fas fa-edit"></i> Edit Product</button></Link>
</div>


    </div>

    </div>
  
  </>
  )
}

export default Bookitem