import DisableDevtool from 'disable-devtool';
import './App.css';
import Navbar from './Navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Login from './Component/Login'
import Home from './Component/Home';
import ProductM from './Component/ProductM';
import Insert from './Component/Insert';
import Update from './Component/Update';
import Searchstate from './Component/context/Searchstate';
import Updateitem from './Component/Updateitem';
import Customer from './Component/Customer';
import Crousaloffer from "./Component/Crousaloffer"
import Order from './Component/Order'
import Cancel from './Component/Cancel';
import Inventory from './Component/Inventory';
import { useEffect } from 'react';
import Reqforbooks from './Component/Reqforbooks';
import Testimonial from './Component/Testimonial';
import Testimonaldel from './Component/Testimonaldel';
import Blog from './Component/Blog';
import Blogdisplay from './Component/Blogdisplay';
import Crousaldel from './Component/Crousaldel';
import Brand from './Component/Brand';
import BrandDisplay from './Component/BrandDisplay';
import Orderhistory from './Component/Orderhistory';
import Coupencode from './Component/Coupencode';
import Showoffer from './Component/Showoffer';
function App() {
useEffect(()=>{
  // DisableDevtool();
},[])
  return (
    <>
<Searchstate>

    <BrowserRouter>
<Navbar/>
<Routes>
  <Route exact path='/login' element={<Login/>}></Route>
  <Route exact path='/' element={<Home/>}></Route>
  <Route exact path='/productmanagement/' element={<ProductM/>}>
  </Route>
    <Route exact path='/insert' element={<Insert/>}></Route>
    <Route exact path='/update' element={<Update/>}></Route>
    <Route exact path='/updateitem/:id' element={<Updateitem/>}></Route>
    <Route exact path='/customer' element={<Customer/>}></Route>

<Route exact path='/order' element={<Order/>}></Route>
<Route exact path='/cancelorder' element={<Cancel/>}></Route>
<Route exact path='/inventory' element={<Inventory/>}></Route>
<Route exact path='/reqforbook' element={<Reqforbooks/>}></Route>



<Route exact path='/publisher' element={<Brand/>}></Route>
<Route exact path='/publisherdisplay' element={<BrandDisplay/>}></Route>
<Route exact path='/orderhistory' element={<Orderhistory/>}></Route>
<Route exact path='/couponcode' element={<Coupencode/>}></Route>
<Route exact path='/showoffer' element={<Showoffer/>}></Route>
</Routes>

    </BrowserRouter>

</Searchstate>
    </>
  );
}

export default App;
