import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const Showoffer = () => {
    const [coupons, setCoupons] = useState([]);
    const [alert, setalert] = useState(false)

    let navigate=useNavigate()
    useEffect(() => {
      // Replace with your API call
      fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/getallcode`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "token":localStorage.getItem("token")
        }
      }).then((res)=>res.json()).then((ele)=>{
        setCoupons(ele.offer)
      })
       
    }, []);
  
    const handleDelete = (code, id) => {
        
        console.log(code,id)
      if (window.confirm('Are you sure you want to delete this coupon?')) {
        // Replace with your API call to delete the coupon
       
        fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/deletecode`,{
          method:"POST",
          headers:{
            "Content-Type":"application/json",
            "token":localStorage.getItem("token")
          },
          body:JSON.stringify({code:code,_id:id})
        }).then((res)=>res.json()).then((ele)=>{
            setalert({message:ele.message,color:ele.color})
            setTimeout(()=>{
               setalert(null) 
               navigate("/")
            },2000)
        })
      }
    };
  return (
    <>
        {alert &&  <div className={`alert alert-${alert.color} alert-dismissible  fade show text-center `} style={{position:"sticky",top:"0",zIndex:"500"}} role="alert">
  <strong className='text-dark'>{alert.message}</strong> 
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
    <div className="custom-table-container ">
    <h2 className="text-center mb-4">Coupon Codes</h2>
    <table className="table table-hover custom-table">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Coupon Code</th>
          <th scope="col">Discount</th>
          <th scope="col">Max Discount</th>
          <th scope="col">Expiry Date</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {coupons.map((coupon,i) => (
            <tr key={i}>
            <td>{coupon.code}</td>
            <td>{coupon.discount}%</td>
            <td>{coupon.max_discount} INR</td>
            <td>{coupon.expirydate}</td>
            <td>
              <button 
                className="btn btn-danger btn-sm"
                onClick={() => handleDelete(coupon.code,coupon._id)}
                >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
        </>
  );
};

export default Showoffer;
