import React, { useEffect } from 'react'
import { useState } from 'react'
import Req from './Minorcomponet/Req'
import { useNavigate } from 'react-router-dom'

const Reqforbooks = () => {
    const [data, setdata] = useState([])
    const navigate=useNavigate()
    useEffect(() => {
        if(!localStorage.getItem("token")){
          navigate("/login")
        }
    
      }, [])
    
    useEffect(()=>{
fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/reqforformsearch`,{
    method:"POST",
    headers:{
        "Content-Type":"application/json"
    }
}).then((res)=>res.json()).then((ele)=>{setdata(ele.request)})
    },[])

    const handlechange=(e)=>{
        fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/reqforformsearch`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },body:JSON.stringify({search:e.target.value})
        }).then((res)=>res.json()).then((ele)=>{setdata(ele.request)})
    }
  return (
    <>
    <main>
        <section>
            <input type="search" name="search" onChange={handlechange} id="search" className='text-dark w-100 mt-3' placeholder='Search' />

<div className="cus-con" >
{
    data.length!=0 && data.map((ele,i)=>{
        return <Req key={i} ele={ele}/>
    })
}  

    </div>
</section>
    </main>
    
    
    </>
  )
}

export default Reqforbooks