import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Couponcode = () => {
    let navigate=useNavigate()
  const [formData, setFormData] = useState({
    couponCode: '',
    discount: '',
    maxDiscount: '',
    expiryDate: ''
  });
  const [alert, setalert] = useState(false)
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.couponCode) {
      newErrors.couponCode = 'Coupon Code is required';
    }
    if (formData.discount <= 0) {
      newErrors.discount = 'Discount must be a positive number';
    }
    if (formData.maxDiscount <= 0) {
      newErrors.maxDiscount = 'Max Discount must be a positive number';
    }
    if (!formData.expiryDate) {
      newErrors.expiryDate = 'Expiry Date is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Handle form submission
     fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/createcode`,{
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            "token":localStorage.getItem("token")
        },body:JSON.stringify({code:formData.couponCode,discount:formData.discount,max_discount:formData.maxDiscount,expirydate:formData.expiryDate})
     }).then((res)=>res.json()).then((res)=>{
      setalert({message:res.message,color:res.color})
      setTimeout(()=>{
         setalert(null) 
         navigate("/")
      },2000)
     })
    }
  };

  return (
    <>
     {alert &&  <div className={`alert alert-${alert.color} alert-dismissible  fade show text-center `} style={{position:"sticky",top:"0",zIndex:"500"}} role="alert">
  <strong className='text-dark'>{alert.message}</strong> 
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
    <div className="custom-container mt-5">
      <div className="custom-card p-4">
        <h2 className="custom-card-title text-center mb-4">Create Coupon</h2>
        <form onSubmit={handleSubmit} noValidate>
          <div className="mb-3">
            <label htmlFor="couponCode" className="form-label">Coupon Code</label>
            <input
              type="text"
              className={`form-control ${errors.couponCode ? 'is-invalid' : ''}`}
              id="couponCode"
              name="couponCode"
              value={formData.couponCode}
              onChange={handleChange}
              placeholder='Enter coupon code In AlphaNumeric'
              required
              />
            {errors.couponCode && <div className="invalid-feedback">{errors.couponCode}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="discount" className="form-label">Discount In Percentage</label>
            <input
              type="number"
              className={`form-control ${errors.discount ? 'is-invalid' : ''}`}
              id="discount"
              name="discount"
              min="0"
              value={formData.discount}
              onChange={handleChange}
              placeholder='Enter discount in number'
              required
              />
            {errors.discount && <div className="invalid-feedback">{errors.discount}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="maxDiscount" className="form-label">Max Discount in INR</label>
            <input
              type="number"
              className={`form-control ${errors.maxDiscount ? 'is-invalid' : ''}`}
              id="maxDiscount"
              name="maxDiscount"
              min="0"
              value={formData.maxDiscount}
              onChange={handleChange}
              placeholder='Enter max discount in number'
              required
              />
            {errors.maxDiscount && <div className="invalid-feedback">{errors.maxDiscount}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="expiryDate" className="form-label">Expiry Date</label>
            <input
              type="date"
              className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`}
              id="expiryDate"
              name="expiryDate"
              min={new Date().toISOString().split('T')[0]}
              value={formData.expiryDate}
              onChange={handleChange}
              required
              />
            {errors.expiryDate && <div className="invalid-feedback">{errors.expiryDate}</div>}
          </div>
          <button type="submit" className="custom-btn w-100">Submit</button>
        </form>
      </div>
    </div>
              </>
  );
};

export default Couponcode;
