import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const Navbar = () => {
  const navigate=useNavigate()

  useEffect(()=>{
    if(!localStorage.getItem("token"))
    {

      navigate("/login")
    }
  },[])
  const logout=(e)=>{
e.preventDefault()
navigate("/")
localStorage.removeItem("token")
  }
  return (
<>
<nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
  <div className="container-fluid font-weight-bold">
    <Link className="navbar-brand font-weight-bold" to="/"><i className="fa-solid fa-book-open fa-lg"></i>Booksindya</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
     
     
      </ul>
   
      {
        !localStorage.getItem("token")?
      <Link to="/login"><button className='btn btn-outline-danger mx-2'><i className="fa-solid fa-right-to-bracket fa-lg"></i> Login</button></Link>
      :<Link to="/" onClick={logout}><button className='btn btn-outline-danger mx-2'><i className="fa-solid fa-right-to-bracket fa-flip-horizontal fa-lg"></i> Logout</button></Link>
      }
      </div>
  </div>
</nav>

</>
  )
}

export default Navbar