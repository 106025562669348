import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const ProductM = () => {
  const navigate=useNavigate()
  
  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/login")
    }

  }, [])

  return (
    <>
<main>

<section className='product-manage '>

<div className="card" >
  <img src="/austin-distel-744oGeqpxPQ-unsplash.jpg" className="card-img-top" alt="..."/>
  <div className="card-body d-flex flex-column justify-content-between">
    <div>

    <h5 className="card-title">INSERT PRODUCT</h5>
 
        <ul className="card-text">
            <li>Book title.</li>
            <li>Author's name.</li>
            <li>Genre or category of the book (e.g., fiction, non-fiction, mystery, self-help).</li>
            <li>A brief summary of the book's plot or subject matter.</li>
            <li>Key themes or messages in the book.</li>
            <li>Any notable awards or recognitions received by the book or author.</li>
            <li>The book's publication date.</li>
            <li>Any special edition or format details (e.g., hardcover, e-book).</li>
            <li>Where the book is available for purchase (e.g., online retailers, local bookstores).</li>
        </ul>
   
    </div>
    <Link to="/insert" className="btn btn-primary"><i className="fa-solid fa-book"></i> INSERT PRODUCT </Link>
  </div>
</div>


<div className="card" >
  <img src="/carlos-muza-hpjSkU2UYSU-unsplash.jpg" className="card-img-top" alt="..."/>
  <div className="card-body d-flex flex-column justify-content-between">
    <div>

    <h5 className="card-title">UPDATE PRODUCT / DELETE PRODUCT</h5>

        <ul className="card-text">
            <li>The title of the book.</li>
            <li>The author's name.</li>
            <li>What kind of update or information you're looking for (e.g., a summary, author details, recent developments related to the book, new editions, etc.).</li>
            <li>Look for an option that allows you to delete or remove the selected product.</li>
            <li>This option is usually represented by an icon like a trash can or a "Delete" button.</li>
            <li>Click on the delete option.</li>
        </ul>
  
    </div>
    <Link to="/update" className="btn btn-primary"><i className="fa-solid fa-pen-to-square"></i> UPDATE PRODUCT / <i className="fa-solid fa-bucket"></i> DELETE PRODUCT</Link>
  </div>
</div>



</section>


</main>
    </>
  )
}

export default ProductM