import React from 'react'
import { useState } from 'react'
const Branditem = (props) => {
    const [alert, setalert] = useState(null)
    const deleteproduct=(e)=>{
      
      e.preventDefault()
      let x=window.confirm("Are you sure")
    if(x){
  
      fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/deletebrand`,{
        method:"DELETE",
        headers:{
          "Content-Type":"application/json"
        },body:JSON.stringify({id:props.ele._id})
      }).then((res)=>res.json()).then((ele)=>{
        if(ele.success){
       window.alert("Product is Delete Successfully")
          setTimeout(()=>{
           
            window.location.reload()
          },2000)
        }
      })
    }
    }
  return (
<>

<div className="card" style={{width: "18rem"}}>
  <img src={`${props.ele.image}`}  className="card-img-top" alt="..."/>
  <div className="card-body">
    <h5 className="card-title">{props.ele.name}</h5>
<button type="button" onClick={deleteproduct} className='btn btn-primary btn-sm'>Delete Product</button>
  </div>
</div>

</>
  )
}

export default Branditem