import React, { useEffect, useState } from 'react'
import Customeritem from './Minorcomponet/Customeritem'
import { useNavigate } from 'react-router-dom'

const Customer = () => {
    const [search, setsearch] = useState("")
    const [sdata, setsdata] = useState([])
    const navigate=useNavigate()
    useEffect(() => {
      if(!localStorage.getItem("token")){
        navigate("/login")
      }
  
    }, [])
useEffect(()=>{
fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/cusquery`,{
    method:"POST",
    headers:{
        "Content-Type":"application/json"
    }
}).then((res)=>res.json()).then((res)=>{
    setsdata(res)
 
})
},[])

const handlechange2=(e)=>{
    e.preventDefault()
setsearch(e.target.value)

}

const handlesub=()=>{
    fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/cusquery`,{
        method:"POST",
        headers:{
            "Content-Type":"application/json"
        },body:JSON.stringify({search:search})
    }).then((res)=>res.json()).then((res)=>{
        setsdata(res)
     
    })
}

  return (
  <> 
<div className=' d-flex align-items-center justify-center'>

<input type="search" className='text-dark my-3 cus-search mt-3' name="search" onChange={handlechange2} id="search" placeholder='Search' />
  <button onClick={handlesub}  className='btn btn-primary'>Submit</button>
</div>
  <main>
    <section className='d-flex justify-content-center my-2'>
<div className="cus-con" >
    {sdata.length!=0 && sdata.map((ele,i)=>{
        return <Customeritem key={i} ele={ele}/>
    })}

    {sdata.length==0 && <h1 className='text-center text-light'>No Query Found</h1>}
</div>

    </section>
  </main>
  </>
  )
}

export default Customer