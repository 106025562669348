import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Orderitem = (props) => {
    let location=useLocation()

    console.log(props.ele)


  const fullsettlement=(e)=>{
    e.preventDefault()
    const con=window.confirm("Are you Sure")
    if(con)
    {

        fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/fullsettlement`,{
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                "token":localStorage.getItem("token")
            },body:JSON.stringify({id:props.ele._id})
        }).then((res)=>res.json()).then((ele)=>{
            console.log(ele)
            if(ele.success)
            {
                window.location.reload()
            }
        })
    }
}
    const cancelorder=(e)=>{
        e.preventDefault()
        const con=window.confirm("Are you Sure")
        if(con)
        {

            fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/cancelorder`,{
                method:"DELETE",
                headers:{
                    "Content-Type":"application/json",
                    "token":localStorage.getItem("token")
                },body:JSON.stringify({id:props.ele._id})
            }).then((res)=>res.json()).then((ele)=>{
                console.log(ele)
                if(ele.success)
                {
                    window.location.reload()
                }
            })
        }
    }


    const orderdelivered=async(e)=>{
      const con=window.confirm("Are you Sure")
      if(con)
      {

          fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/orderdelivered`,{
              method:"DELETE",
              headers:{
                  "Content-Type":"application/json",
                  "token":localStorage.getItem("token")
              },body:JSON.stringify({id:props.ele._id})
          }).then((res)=>res.json()).then((ele)=>{
              console.log(ele)
              if(ele.success)
              {
                  window.location.reload()
              }
          })
      }
    }



    
  return (
    <>
    
    <div className="order-card mt-4">
      <div className="top-order">
       <b>#Order Id</b>
     
       
       <b>#user_contacts</b>
       <b>Items</b>
       <b>ISBN No.</b>
       <b>Quantity</b>
       <b>Item price</b>
       <b>Status</b>
       <b>Total price</b>
       <b>Order Date</b>
       {location.pathname==="/cancelorder" && <b>Reason</b>}
     

      </div>
      <div className="bottom-order">
      <div>
   
      <b>{props.ele.order_id}</b>
      </div>


   

      <div>
   
      <b> Name:{props.ele.username} <br /> {props.ele.user_no} <br /> {props.ele.user_email} <br /> {props.ele.address_line1} { props.ele.address_line2} { props.ele.landmark} { props.ele.pincode} {props.ele.state} {props.ele.town} </b>
      </div>
   
   
      <div >
       {props.ele.order_detail.map((ele,i)=>{
         return <b key={i}><br />{ele}</b>
       })}
      </div>

      <div >
       {
        props.ele.isbn.map((ele,i)=>{
          return <b key={i}><br />{ele}</b>
        })
       }
      </div>
   
   
      <div>
      {props.ele.quantity.map((ele,i)=>{
        return <b key={i}><br />{ele}</b>
       })}
      </div>
   
      <div >
      {props.ele.itemprice.map((ele,i)=>{
        return <b key={i}><br /><i className="fa-solid fa-indian-rupee-sign"></i>{ele.toFixed(2)}</b>
       })}
      </div>

      <div>
       <b>{props.ele.status}</b>
      </div>
      <div>
       <b><i className="fa-solid fa-indian-rupee-sign"></i>{parseFloat(props.ele.total_amount).toFixed(2)}</b>
      </div>
      <div>
        <b>{props.ele.dateofadd}</b>
      </div>
     {
location.pathname==="/cancelorder" &&
       <div>
        <b>{props.ele.reason}</b>
      </div>
    } 

     
    </div>
    <div className="bottom-footer ">
        <button type="button" className='btn btn-success btn-sm' onClick={orderdelivered}>Order Dispatched</button>
        <button type="button" className='btn btn-danger btn-sm' onClick={cancelorder}>Order Cancel</button>
     { location.pathname==="/cancelorder" &&  <button type="button" className='btn btn-primary btn-sm' onClick={fullsettlement}>Full Settlement</button>}
    </div>
       </div> 
    </>
  )
}

export default Orderitem