import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate=useNavigate()
  const [alert, setalert] = useState(null)
  useEffect(() => {
    if(localStorage.getItem("token")){
      navigate("/")
    }

  }, [])
  
const [credentials, setcredentials] = useState({username:"",password:""})
const handlechange=(e)=>{
e.preventDefault()
setcredentials({...credentials,[e.target.name]:e.target.value})

}

  const onsubmit=async(e)=>{
e.preventDefault()
const response=await fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/adminlogin`,{
  method:"POST",
  headers:{
    "Content-Type":"application/json"
  },body:JSON.stringify({username:credentials.username,password:credentials.password})
})

const json=await response.json()
if(json.success){
  localStorage.setItem("token",json.token)
  setalert({message:"Thankyou For Signin Ritesh Srivastav",color:"success"})
  setTimeout(()=>{
setalert(null)
    navigate("/")
  },2000)
}else
{

  setalert({message:"Invalid Admin",color:"danger"})
  setTimeout(()=>{

   setalert(null)
  },2000)
}

  }
  return (
    <>
    <div>
    {alert && <div className={`alert alert-${alert.color}  text-center alert-dismissible fade show`} role="alert">
  <strong className='text-dark'>{alert.message}</strong>
  
</div>}
<main className="form-signin w-100 m-auto my-4">
  <form onSubmit={onsubmit}>
    <img className="mb-4" src="/logo2.jpg" alt="" width="250" height="200"/>
    <h1 className="h3 mb-3 fw-normal text-dark">Please Log in</h1>

    <div className="form-floating">
      <input type="text " className="form-control" name='username' onChange={handlechange} minLength={6} id="floatingInput" placeholder="name@example"/>
      <label htmlFor="floatingInput">Username</label>
    </div>
    <div className="form-floating">
      <input type="password" className="form-control" name='password' onChange={handlechange}  minLength={6} id="floatingPassword" placeholder="Password"/>
      <label htmlFor="floatingPassword">Password</label>
    </div>


    <button className="btn btn-primary w-100 py-2" type="submit">Sign in</button>
    <p className="mt-5 mb-3 text-body-secondary">&copy; All Right Reserve Lokesh Raikwar</p>
  </form>
</main>


    </div>
  </>
  )
}

export default Login