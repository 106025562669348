
import React, { useContext, useEffect, useState } from "react";
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Context from "./context/Searchcontext";
import { useLocation, useParams } from "react-router-dom";
import Bookitem from "./Minorcomponet/Bookitem";
import Orderitem2 from "./Minorcomponet/Orderitem";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};

const Orderhistory = () => {
  const [length, setlength] = useState(1)
  const [hasMore, setHasMore] = useState(true);
  const [search, setsearch] = useState('')
  const [page, setPage] = useState(0)
  const [items, setItems] = useState([])
  
let {id}=useParams()
let location=useLocation()
useEffect(()=>{
  document.documentElement.scrollTo({
    top:0,
    behavior:"smooth"
  })

},[location])

  useEffect(() => {
    setItems([])
    setPage(0)
    setHasMore(true)
    fetchData();
  }, [id,location,search]);

  let typingTimer;
  const doneTypingInterval = 1000;
  const searchstate=(e)=>{
    setItems([])
    setHasMore(true)
    clearTimeout(typingTimer);
    typingTimer = setTimeout(()=>{ 
      setsearch(e.target.value)
      setPage(0)
    }, doneTypingInterval);
  }

  const fetchData = () => {

    const url = `${process.env.REACT_APP_FETCH_ADDRESS_KEY}/getallprev`;

    fetch(url,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
        "token":`${localStorage.getItem("token")}`
      },body:JSON.stringify({search:search,skip:page})
    })
      .then(response => response.json())
      .then(data => {
        let combinedArray = items.concat(data.order);
        let uniqueSet =[...new Set(combinedArray)];
        
        setItems(uniqueSet);
        setPage(prevPage => prevPage + 20); 
        setlength(data.order.length)
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const fetchMoreData = () => {
    fetchData();
    
  };

  useEffect(()=>{
if(length===0){
  setHasMore(false)
}
  },[page])

  return (


    <div className="container d-flex flex-column align-items-center justify-content-center">
    <div class="row w-100">

    <input type="search" onChange={searchstate} class="form-control w-100" placeholder="Search Books" aria-label="First name"/>
  </div>

    <div className="mt-4">

    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<img src="/Ripple-1.1s-64px.svg"/>}
      endMessage={<p>No more items to load</p>}
    >
      {items.map(ele => (
      <Orderitem2  ele={ele}/>
      ))}
    </InfiniteScroll>
  </div>
      </div>
  )
}

export default Orderhistory