import React, { useState } from 'react'
import Context from '../context/Searchcontext'
const Searchstate = (props) => {

   const [search, setsearch] = useState(88)
  return (
    <Context.Provider value={{search,setsearch}} >
        {props.children}
    </Context.Provider>
  )
}

export default Searchstate