import React from 'react'

const Customeritem = (props) => {
  
  const resolve=async(e)=>{
      let x=window.confirm()
        e.preventDefault()

        if(x){

          
          fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/resolve`,{
            method:"DELETE",
            headers:{
              "Content-Type":"application/json",
              "token":localStorage.getItem("token")
            },body:JSON.stringify({_id:props.ele._id})
          }).then((res)=>res.json()).then((res)=>{
            if(x)
            {
              window.location.reload()
            }
          })
        }

    }
  return (
  <>

<div className="card">
  <h5 className="card-header">{props.ele.name}</h5>
  <div className="card-body">
    <h5 className="card-title">{props.ele.email}</h5>
    <h5 className="card-title">{props.ele.pno}</h5>
 
    <p className="card-text">{props.ele.suggestion}</p>
    <a href="#" className="btn btn-primary" onClick={resolve}><i className="fa-solid fa-list-ol"></i> Resolve</a>
  </div>
</div>
  </>
  )
}

export default Customeritem