import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate=useNavigate()
  
  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/login")
    }

  }, [])
  return (
  <>
<main className='dashboard p-3'>
<section>
  <div className="container dashboard-container ">
  <Link to="/productmanagement">
  <div className="card " style={{maxWidth: "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="/bench-accounting-C3V88BOoRoM-unsplash.jpg " style={{height:"100%"}} className="img-fluid rounded-start" alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Product Management</h5>
        <p className="card-text">
Product management is the art of transforming ideas into market-ready innovations with precision and strategic finesse.</p>
       
      </div>
    </div>
  </div>
</div>
  </Link>
<Link to="/inventory">

<div className="card " style={{maxWidth: "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="/jexo-oydF7IKn6Bk-unsplash.jpg" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Inventory Management</h5>
        <p className="card-text">Inventory management is the art of maintaining the perfect supply-and-demand equilibrium for seamless business operations.</p>
      
      </div>
    </div>
  </div>
</div>

</Link>
<Link to="/order">
<div className="card" style={{maxWidth: "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="/scott-graham-5fNmWej4tAA-unsplash.jpg" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Order Management</h5>
        <p className="card-text">Order management is the conductor of the harmonizing customer requests and fulfillment to create a seamless and satisfying shopping symphony.</p>
      
      </div>
    </div>
  </div>
</div>
</Link>
<Link to="/customer">



<div className="card " style={{maxWidth: "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="https://plus.unsplash.com/premium_photo-1679429320552-ec9038ccd550?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Customer Management</h5>
        <p className="card-text">Customer management: where transactions transform into relationships, and every interaction is a brushstroke on the canvas of trust.</p>
       
      </div>
    </div>
  </div>
</div>

</Link>
<Link to="/cancelorder">

<div className="card" style={{maxWidth: "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="/onur-binay-Uw_8vSroCSc-unsplash.jpg" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Return and Replacement Request</h5>
        <p className="card-text">The "cancel request" button: a digital escape hatch for impulsive clicks, a second chance before the delivery journey begins.</p>
     
      </div>
    </div>
  </div>
</div>

</Link>
<Link to="/reqforbook">

<div className="card" style={{maxWidth: "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGJvb2tzfGVufDB8fDB8fHww" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Request for Books</h5>
        <p className="card-text">Its is a request created by customer for he/she want their is available as soon as posible</p>
       
      </div>
    </div>
  </div>
</div>
</Link>








<Link to="/publisher">

<div className="card" style={{maxWidth: "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="https://plus.unsplash.com/premium_photo-1681761010449-5879c0fd36c3?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Insert Publisher Name</h5>
        <p className="card-text">"the educational experience here not only broadened my knowledge but also nurtured my personal and professional growth remarkably."</p>
       
      </div>
    </div>
  </div>
</div>
</Link>


<Link to="/publisherdisplay">

<div className="card" style={{maxWidth: "540px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="https://images.unsplash.com/photo-1519791883288-dc8bd696e667?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Delete Publisher Name</h5>
        <p className="card-text">"the educational experience here not only broadened my knowledge but also nurtured my personal and professional growth remarkably."</p>
       
      </div>
    </div>
  </div>
</div>
</Link>



<Link to="/orderhistory">

<div className="card" style={{maxWidth: "1040px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="/Designer2.png" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Get All Order History</h5>
        <p className="card-text">"In this Option You See Your History"</p>
       
      </div>
    </div>
  </div>
</div>
</Link>

<Link to="/couponcode">

<div className="card" style={{maxWidth: "1040px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="/Designer.png" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Create Coupon Code</h5>
        <p className="card-text">"Make Coupon Code and Attract Customer"</p>
       
      </div>
    </div>
  </div>
</div>
</Link>

<Link to="/showoffer" >

<div className="card" style={{maxWidth: "1040px"}}>
  <div className="row g-0">
    <div className="col-md-4">
      <img src="/Designer.png" className="img-fluid rounded-start" style={{height:"100%"}} alt="..."/>
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <h5 className="card-title">Show All Coupon Code</h5>
        <p className="card-text">"Display All Coupons Codes and You Can Delete"</p>
       
      </div>
    </div>
  </div>
</div>
</Link>






  </div>
</section>
</main>
  
  </>
  )
}

export default Home