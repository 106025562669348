import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
const Brand = () => {
    const navigate=useNavigate()
    useEffect(() => {
        if(!localStorage.getItem("token")){
          navigate("/login")
        }
    
      }, [])
    const [base64Image, setBase64Image] = useState("");
    const [img, setimg] = useState("")
    const [cred, setcred] = useState({title:""})
    const [alert, setalert] = useState(null)

    const handlechange2=(e)=>{
        e.preventDefault()
        console.log(cred)
        setcred({...cred,[e.target.name]:e.target.value})
    }

    const handlesubmit=(e)=>{
        e.preventDefault()
        fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/insertbrand`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },body:JSON.stringify({image:`data:image/jpeg;base64,${base64Image}`,name:cred.title})
        }).then((res)=>res.json()).then((ele)=>{
            
            setalert({message:ele.message,color:ele.color})
            setTimeout(()=>{
                setalert(null)
                window.location.reload()
            },2000)
        })
    }
    const handlechange=(e)=>{
        e.preventDefault()
     
       
          

          
          var url = URL.createObjectURL(e.target.files[0])
          setimg(url)
         
    
          let file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
      
            reader.onload = (f) => {
              // Convert the ArrayBuffer to base64
              let base64String = btoa(new Uint8Array(f.target.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                
              setBase64Image(base64String);
            
            };
      
            // Read the file as ArrayBuffer
            reader.readAsArrayBuffer(file);
          }
       
      
        
    
        
      }
  return (
<>
{alert && <div className={`alert alert-${alert.color}  text-center alert-dismissible fade show`} role="alert">
  <strong className='text-dark'>{alert.message}</strong>
  
</div>}
   <section className='d-flex justify-content-center mt-3'>
<div className='test-container'>
    <h1>OUR PUBLISHER</h1>
    <form onSubmit={handlesubmit}>

    <div className='d-flex justify-content-between'>
      
<label htmlFor="image">Image</label>
<input type="file" name="image" onChange={handlechange} accept='image/jpeg' id="image" />
        
<img src={img} width="100px" alt="preview"  />
    </div>
    <div>
<label htmlFor="title">Title</label>
<input type="text" className='text-dark' onChange={handlechange2} name="title" id="title" />
    </div>
   
    <div className='mt-3'>
        <button type='submit' className='btn btn-warning btn-sm'>Submit</button>
    </div>
    </form>
</div>

   </section>
   

</>
  )
}

export default Brand