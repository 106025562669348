import React from 'react'

const Req = (props) => {

    const deletereq=(e)=>{
        let x=window.confirm("Are you sure")
        if(x){
            fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/reqforformdelete`,{
                method:"DELETE",
                headers:{
                    "Content-Type":"application/json"
                },body:JSON.stringify({id:props.ele._id})
            }).then((res)=>res.json()).then((ele)=>{
                if(ele.success){
                    window.location.reload()
                }
            })
        }
    }
  return (
  <>
              <div className="card border-warning my-3" >
  <div className="card-header">{props.ele.email}</div>
  <div className="card-body">
    <h5 className="card-title">{props.ele.pno}</h5>
    <h5 className="card-title">Booktitle {props.ele.title}</h5>
    <p className="card-text">Book Auther {props.ele.auther}</p>
    <p className="card-text">Book Publisher {props.ele.publisher}</p>
    <button type="button" onClick={deletereq} className='btn btn-success btn-sm'>Delete Request</button>
  </div>
</div>

  </>
  )
}

export default Req