
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {decode as base64_decode, encode as base64_encode} from 'base-64';
const Insert = () => {

  const [review, setreview] = useState(0)
const [alert, setalert] = useState(false)
const navigate=useNavigate()
const [base64Image, setBase64Image] = useState({frontimg:"",backimg:"",fconimg:"",sconimg:""});
const [credentials, setcredentials] = useState({booktitle:"",bookdescription:"",stock:"",weight:"",category:"",pages:"",auther:"",mrp:"",discount:"",actual:"",publication:"",binding:"Paper Back",isbn:""})
  const [img, setimg] = useState({frontimg:"",backimg:"",fconimg:"",sconimg:""})
  const [upload, setupload] = useState({frontimg:"",backimg:"",fconimg:"",sconimg:""})
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/login")
    }

  }, [])

  const handlechange2=async(e)=>{
    e.preventDefault()
   
    if(credentials.imgtext1 || credentials.imgtext2 || credentials.imgtext3 || credentials.imgtext4)
    {
      setcredentials({...credentials,[e.target.name]:e.target.value})

    }
    else{
      setcredentials({...credentials,[e.target.name]:e.target.value})
    }
    
  }




  const handlechange=(e)=>{
    e.preventDefault()
 
    if(e.target.files[0].type==="image/jpeg" || e.target.files[0].size>1000000)
    {
      

      setupload({...upload,[e.target.name]:e.target.files[0]})
      
      var url = URL.createObjectURL(e.target.files[0])
      setimg({...img,[e.target.name]:url})
     

      let file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
  
        reader.onload = (f) => {
          // Convert the ArrayBuffer to base64
          let base64String = btoa(new Uint8Array(f.target.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
  
          setBase64Image({...base64Image,[e.target.name]:base64String});
        
        };
  
        // Read the file as ArrayBuffer
        reader.readAsArrayBuffer(file);
      }
   
    }
    else{
      setalert({message:"Please Upload Image Only JPG Format And File Is Less Than 1MB ",color:"warning"})
    }

    
  }

  const checksub=(e)=>{
    e.preventDefault()
    if(!credentials.weight){
      setalert({message:"Weight is Empty",color:"danger"})
      return
    }
    if(credentials.booktitle.length<=10 )
    {
      setalert({message:"Booktitle must be in 10 character",color:"danger"})
      return
    }
    if(credentials.weight>=2000)
    {
      setalert({message:"Books Weight Above 2000gm",color:"danger"})
      return
    }
     
    if(!credentials.binding )
    {
      setalert({message:"Binding Not Set",color:"danger"})
      return
    }

    if( credentials.stock==0 || credentials.stock<0)
    {
      setalert({message:"Stock Not Set ",color:"danger"})
      return
    }

    if( credentials.pages==0 || credentials.pages<0)
    {
      setalert({message:"Page Not Set",color:"danger"})
      return
    }
    if(credentials.actual==0 || credentials.actual<0)
    {
      setalert({message:"Actual Price Not Set",color:"danger"})
      return
    }

    if(credentials.discount==0 || credentials.discount<0)
    {
      setalert({message:"Discount Not Set",color:"danger"})
      return
    }

    if(credentials.mrp==0 || credentials.mrp<0 )
    {
      setalert({message:"MRP Not Set",color:"danger"})
      return
    }

    if(credentials.isbn.length==0 || credentials.isbn.length<0)
    {
      setalert({message:"ISBN Number Not Set",color:"danger"})
      return
    }
  
    if(credentials.bookdescription.length<=26)
    {
      setalert({message:"Bookdescription must be in 26 character",color:"danger"})
      return
    }
    if(credentials.category.length<=5)
    {
      setalert({message:"Category Must Be 5 Character",color:"danger"})
      return
    }
    if(credentials.publication.length<=5)
    {
      setalert({message:"Publication must be 5 Character",color:"danger"})
      return
    }
    if( credentials.auther.length<=5)
    {
      setalert({message:"Auther must be 5 Character",color:"danger"})
      return
    }
    setreview(2)

  }

useEffect(()=>{
  setTimeout(()=>{

    setalert(false)
  },3000)
},[alert])

const onSubmit=(e)=>{
  e.preventDefault()
 
  
  if(upload.frontimg.length===0 )
  {
    setalert({message:"Please Upload The First Image ",color:"danger"})
    return
  }
  if( upload.backimg.length===0 )
  {
    setalert({message:"Please Upload The Second Image",color:"danger"})
    return
  }
  if( upload.fconimg.length===0 )
  {
    setalert({message:"Please Upload The Third Image",color:"danger"})
    return
  }
  if(upload.sconimg.length===0)
  {
    setalert({message:"Please Upload The Fourth Images",color:"danger"})
    return
  }

  setreview(1)
  
}




  const onSubmit2=async(e)=>{
    e.preventDefault()
    

    
    const response=await fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/insertproduct`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
        
      },
      body:JSON.stringify({booktitle:credentials.booktitle,bookdescription:credentials.bookdescription,isbn:credentials.isbn,bimg1:`data:image/jpeg;base64,${base64Image.frontimg}`,bimg2:`data:image/jpeg;base64,${base64Image.backimg}`,bimg3:`data:image/jpeg;base64,${base64Image.fconimg}`,bimg4:`data:image/jpeg;base64,${base64Image.sconimg}`,category:credentials.category,mrp:credentials.mrp,discount:credentials.discount,actualprice:credentials.actual,stock:credentials.stock,pages:credentials.pages,auther:credentials.auther,publication:credentials.publication,weight:credentials.weight,binding:credentials.binding})
      
    })
    const json=await response.json()

    if(json.success)
    {
     
      setalert({message:json.message,color:"success"})
      setloading(true)
      setTimeout(()=>{
        setloading(false)
       
        navigate("/productmanagement")
      },3000)
      return
    }
    else{
      setalert({message:json.message,color:"danger"})
      return
      }
    

setalert({message:"SomeThing Went Wrong Try Again After SomeTimes",color:"danger"})
      
 
  
  }
  

  return (
    <>
    
  {alert &&  <div className={`alert alert-${alert.color} alert-dismissible  fade show text-center `} style={{position:"sticky",top:"0",zIndex:"500"}} role="alert">
  <strong className='text-dark'>{alert.message}</strong> 
  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>}
    
    {loading? <div className='loading'>

    <div className="loadingio-spinner-double-ring-n0o47wvzkh"><div className="ldio-y0qpps7l8gf">
<div></div>
<div></div>
<div><div></div></div>
<div><div></div></div>
</div></div></div>: <main>
    
      
<section className='d-flex justify-content-center align-items' >
<div className="insert-form d-flex justify-content align-items my-4">

{
  review===0 &&
  <form onSubmit={onSubmit}>
    <h1 className='text-center fw-bold'>Insert Images Of The Books</h1>
<div className="mb-3 d-flex gap-2 ">
<div>

<label htmlFor="formFile" className="form-label">Insert the Front Image of the Book</label>
<input className="form-control file-selector-button my-2" accept='image/jpeg' onChange={handlechange} name="frontimg" type="file" id="formFile1"/>

</div>
<img src={img.frontimg} width="100px" className="rounded float-end" alt="preview"></img>

</div>


<div className="mb-3 d-flex gap-2">
<div>
<label htmlFor="formFile" className="form-label">Insert the Back Image of the Book</label>

<input className="form-control file-selector-button my-2"  accept='image/jpeg' onChange={handlechange} name='backimg'  type="file" id="formFile2"/>
</div>
  <img src={img.backimg} width="100px" className="rounded float-end" alt="preview"></img>


</div>

<div className="mb-3 d-flex gap-2">
<div>
<label htmlFor="formFile3" className="form-label">Insert the First Content Image of the Book</label>

<input className="form-control file-selector-button my-2 " accept='image/jpeg'  onChange={handlechange} name='fconimg' type="file" id="formFile3"/>
</div>
<img src={img.fconimg} width="100px" className="rounded float-end" alt="preview"></img>


</div>


<div className="mb-3 d-flex gap-2">
<div>
<label htmlFor="formFile4" className="form-label">Insert the Second Content Image of the Book</label>

<input className="form-control file-selector-button my-2" accept='image/jpeg'  onChange={handlechange} name='sconimg' type="file" id="formFile4"/>
</div>
<img src={img.sconimg} width="100px"  className="rounded float-end" alt="preview"></img>


</div>
<div className="mb-3 d-flex gap-2">
<button type='submit' className='btn btn-dark'>Go Next <i className="fa-solid fa-arrow-right"></i></button>

</div>
  </form>
}
   {review===1 && <form onSubmit={checksub}>
<h1 className='text-center text-decoration-underline'>INSERT BOOK</h1>
<div className="mb-3 ">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">BOOK TITLE</label>
  <textarea className="form-control" id="book-title" value={credentials.booktitle}  onChange={handlechange2} name='booktitle' placeholder='Enter the Your Book Title' rows="3"></textarea>
</div>

<div className="mb-3 ">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">BOOK DESCRIPTION</label>
  <textarea className="form-control" id="book-description" value={credentials.bookdescription}  onChange={handlechange2}  name='bookdescription' rows="3" placeholder='Enter the Your Book Description'></textarea>
</div>



<div className="mb-3">
  <label htmlFor="Stock" className="form-label">Enter Total Stock of the book</label>
  <input className="form-control " onChange={handlechange2}  type="number" id='category' value={credentials.stock} name="stock" placeholder='Enter Total Stock of the book' aria-label="readonly input example" ></input>
  
 
</div>

<div className="mb-3">
  <label htmlFor="category" className="form-label">Enter the Categories of the book</label>
  <input className="form-control " onChange={handlechange2}  type="text" id='category' value={credentials.category} name="category" placeholder='Enter Total Category of the book' aria-label="readonly input example" ></input>
 
</div>

<div className="mb-3">
  <label htmlFor="pages" className="form-label">No. Of Pages</label>
  <input className="form-control "  onChange={handlechange2} type="number" id='pages'   value={credentials.pages} name="pages"   placeholder='Enter the total pages in book' aria-label="readonly input example" ></input>
 
</div>

<div className="mb-3">
  <label htmlFor="Auther-Name" className="form-label">Auther's Name</label>
  <input className="form-control " onChange={handlechange2}  type="text" id='Auther-Name'  value={credentials.auther} name='auther' placeholder='Enter the auther o book' aria-label="readonly input example" ></input>
 
</div>

<div className="mb-3">
  <label htmlFor="book-mrp" className="form-label">Book Maximum Retail Price</label>
  <input className="form-control " onChange={handlechange2} type="number" id='book-mrp'   name="mrp" value={credentials.mrp}  placeholder='Enter the MRP of book' aria-label="readonly input example" ></input>
 
</div>


<div className="mb-3">
  <label htmlFor="book-discount" className="form-label">Book Discount percentage</label>
  <input className="form-control " onChange={handlechange2} type="number" id='book-discount'  name="discount" value={credentials.discount}  placeholder='Book Discount Percentage' aria-label="readonly input example" ></input>
 
</div>

<div className="mb-3">
  <label htmlFor="book-ap" className="form-label">Book Actual Price</label>
  <input className="form-control " onChange={handlechange2} type="number" id='book-ap' name="actual"  value={credentials.actual}  placeholder='Book Actual Price' aria-label="readonly input example" ></input>
 
</div>

<div className="mb-3">
  <label htmlFor="book-ap" className="form-label">ISBN</label>
  <input className="form-control " onChange={handlechange2} type="number" id='isbn' name="isbn"   value={credentials.isbn}  placeholder='ISBN NUMBER' aria-label="readonly input example" ></input>
 
</div>

<div className="mb-3">
  <label htmlFor="publication" className="form-label">Publication</label>
  <input className="form-control " onChange={handlechange2} type="text" id='publication'     value={credentials.publication} name="publication"  placeholder='Book Publication' aria-label="readonly input example" ></input>
 
</div>
<div className="mb-3">
  <label htmlFor="publication" className="form-label">Book Weight</label>
  <input className="form-control " onChange={handlechange2} type="text" id='weight'     value={credentials.weight} name="weight"   placeholder='Book Weight in gram eg 1kg=1000gm' aria-label="readonly input example" ></input>
 
</div>

<select className="form-select form-select-lg mb-3" aria-label="Large select example" value={credentials.binding} onChange={handlechange2} name='binding'>
  
  <option value="Paper Back">Paper Back</option>
  <option value="Hard Cover">Hard Cover</option>

</select>

<div className="mb-3">

<button className='btn btn-dark btn-lg' style={{width:"100%"}}>Preview Detail</button>
</div>
    </form>
}
{
  review===2 &&

    <form onSubmit={onSubmit2}>
      <h1 className='text-decoration-underline text-center fw-bold'>Preview Form</h1>
      <div className="container d-flex justify-content-between">
        <div>

        <h2 className='fw-bold text-decoration-underline'>Book Title</h2>
        {credentials.booktitle}
        </div>
      
      </div>

      <div className="container d-flex justify-content-between">
        <div>

        <h2 className='fw-bold text-decoration-underline'>Book Description</h2>
        {credentials.bookdescription}
        </div>
       
      </div>

      <div className="container  my-2 d-flex justify-content-between">
        <div>

        <h2 className='fw-bold text-decoration-underline'>Book Front Image</h2>
        {credentials.imgtext1}
        </div>
  <img src={img.frontimg} width="100px" className="rounded float-end" alt="preview"></img>

       
      </div>


      <div className="container  my-2 d-flex justify-content-between">
        <div>

        <h2 className='fw-bold text-decoration-underline'>Book Back Image</h2>
        {credentials.imgtext2}
        </div>
  <img src={img.backimg} width="100px" className="rounded float-end" alt="preview"></img>

       
      </div>



      <div className="container d-flex my-2 justify-content-between">
        <div>

        <h2 className='fw-bold text-decoration-underline'>Book First Content Image</h2>
        {credentials.imgtext1}
        </div>
  <img src={img.fconimg} width="100px" className="rounded float-end" alt="preview"></img>

       
      </div>



      
      <div className="container d-flex my-2 justify-content-between">
        <div>

        <h2 className='fw-bold text-decoration-underline'>Book Second Content Image</h2>
        {credentials.imgtext1}
        </div>
  <img src={img.sconimg} width="100px" className="rounded float-end" alt="preview"></img>

       
      </div>
      

      
      
      <div className="container my-3 d-flex justify-content-between">
        <div className='fs-1 fw-semibold'>

        <h2 className='fw-bold text-decoration-underline'>Book Stock</h2>
       <p className='fst-italic'> {credentials.stock} Quantity</p>
        </div>
       
       
      </div>

      <div className="container my-3 d-flex justify-content-between">
        <div className=' fw-semibold'>

        <h2 className='fw-bold text-decoration-underline'>Book Category</h2>
       <p className='fst-italic'> {credentials.category} </p>
        </div>
       
       
      </div>


      <div className="container my-3 d-flex justify-content-between">
        <div className='fs-1 fw-semibold'>

        <h2 className='fw-bold text-decoration-underline'>No Of Pages Of the Book</h2>
       <p className='fst-italic'> {credentials.pages} </p>
        </div>
       
        
      </div>

      <div className="container my-3 d-flex justify-content-between">
        <div className=' fw-semibold'>

        <h2 className='fw-bold text-decoration-underline'>Book Auther</h2>
       <p className='fst-italic'> {credentials.auther} </p>
        </div>
       
     
      </div>

      <div className="container my-3 d-flex justify-content-between">
        <div className='fs-1 fw-semibold'>

        <h2 className='fw-bold  text-decoration-underline'>Maximum Retail Price</h2>
       <p className='fst-italic'> {credentials.mrp} <i className="fa-solid fa-indian-rupee-sign"></i></p>
        </div>
       

      </div>

      <div className="container my-3 d-flex justify-content-between">
        <div className='fs-1 fw-semibold'>

        <h2 className='fw-bold  text-decoration-underline'>Discount Percentage</h2>
       <p className='fst-italic'> {credentials.discount} <i className="fa-solid fa-percent"></i></p>
        </div>
       
 
      </div>

      <div className="container my-3 d-flex justify-content-between">
        <div className='fs-1 fw-semibold'>

        <h2 className='fw-bold  text-decoration-underline'>Actual Price</h2>
       <p className='fst-italic'> {credentials.actual} <i className="fa-solid fa-indian-rupee-sign"></i></p>
        </div>
       

      </div>
      <div className="container my-3 d-flex justify-content-between">
        <div className='fs-1 fw-semibold'>

        <h2 className='fw-bold  text-decoration-underline'>ISBN Number</h2>
       <p className='fst-italic'> {credentials.isbn} </p>
        </div>
       

      </div>

      <div className="container my-3 d-flex justify-content-between">
        <div className=' fw-semibold'>

        <h2 className='fw-bold  text-decoration-underline'>Book Publication</h2>
       <p className='fst-italic'> {credentials.publication} </p>
        </div>
       
        
      </div>


      <div className="container my-3 d-flex justify-content-between">
        <div className=' fw-semibold'>

        <h2 className='fw-bold  text-decoration-underline'>Book Weight</h2>
       <p className='fst-italic'> {credentials.weight} </p>
        </div>
       
        
      </div>



      <div className="container my-3 d-flex justify-content-between">
        <div className=' fs-1 fw-semibold'>

        <h2 className='fw-bold  text-decoration-underline'>Book Binding</h2>
       <p className='fst-italic'> {credentials.binding} </p>
        </div>
       
       
      </div>
      <div className='d-flex justify-content-center gap-2'>

      <button className='btn btn-dark btn-lg' type='submit'><i className="fa-solid fa-thumbs-up"></i> Submit</button>
      <button className='btn btn-dark btn-lg' type='submit' onClick={()=>{setreview(0)}}><i className="fa-solid fa-pen-nib"></i> Edit Something</button>
      </div>
    </form>
}
    
</div>

</section>

    </main>
    
}
    </>
  )
}

export default Insert