import React, { useEffect } from 'react'
import { useState } from 'react'
import Blogitem from './Minorcomponet/Blogitem'
import { useNavigate } from 'react-router-dom'
import Branditem from './Minorcomponet/Branditem'
const BrandDisplay = () => {
  
  const navigate=useNavigate()
  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/login")
    }

  }, [])
    const [cred, setcred] = useState([])
  

    useEffect(()=>{
fetch(`${process.env.REACT_APP_FETCH_ADDRESS_KEY}/displaybrand`,{
    method:"POST"
}).then((res)=>res.json()).then((ele)=>{setcred(ele.data)})
    },[])

  return (
    <>
    {alert && <div className={`alert alert-${alert.color}  text-center alert-dismissible fade show`} role="alert">
    <strong className='text-dark'>{alert.message}</strong>
    
  </div>}
    <section className='d-flex'>
        <div className="cus-con" >

{cred.length!==0 && cred.map((ele,i)=>{
    return <Branditem key={i} ele={ele}/>
})}

{cred.length===0 && <h1>No Product Found</h1>}
        </div>

    </section>
</>
  )
}

export default BrandDisplay